export const isAuthenticated = () => {
    // 检查 localStorage 是否有 token，作为是否认证的依据
    const token = process.env.REACT_APP_SECRET;
    return !!localStorage.getItem('token');
};

export const getToken = () => {
    return localStorage.getItem('token');
}

export const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/'; // 跳转到首页
};

export const generateSlug = async (title) => {
    // 设置Google Translate API密钥
    const API_KEY = 'AIzaSyC_ZQrMUWQRmjxvFPbq2GDROUBh0BzNirY';
    
    // 翻译中文标题
    const url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            q: title,
            source: 'zh-CN',
            target: 'en',
            format: 'text'
        })
    });
    
    if (!response.ok) {
        throw new Error('Translation API request failed');
    }

    const responseData = await response.json();
    const translatedTitle = responseData.data.translations[0].translatedText;
    
    // 将英文转换为URL友好的格式
    const slug = translatedTitle
        .replace(/[^a-zA-Z0-9\s-]/g, '')  // 移除特殊字符
        .replace(/\s+/g, '-')             // 将空格替换为连字符
        .toLowerCase();                   // 转为小写

    return slug;
}