import React, { createContext, useContext, useState } from 'react';

// 创建 AuthContext
const AuthContext = createContext();

// 创建 AuthProvider 组件
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

// 自定义 hook 用于使用上下文
export const useAuth = () => {
    return useContext(AuthContext);
};
