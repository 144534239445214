import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate 用于导航
import { useAuth } from './AuthContext'; // 导入 useAuth
import './Login.css'; // 引入 CSS 文件

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null); // 用于存储错误信息
    const [loading, setLoading] = useState(false); // 用于存储加载状态
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth(); // 使用自定义 hook
    const apiUrl = process.env.REACT_APP_API_URL;

    // 假设这是登录成功后的响应处理
    const handleLogin = async (event) => {
        event.preventDefault(); // 阻止表单默认提交行为
        setLoading(true); // 开始加载
        try {
            const response = await fetch(`${apiUrl}/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            });
    
            const data = await response.json();
            if (data.token) {
                // 将 token 存储在 localStorage 中
                localStorage.setItem('token', data.token);
                // 更新认证状态
                setIsAuthenticated(true);
                // 跳转到主页或其他页面
                navigate('/'); // 跳转到主页
            }
        } catch (error) {
            console.error(error);
            setError(error.message); // 设置错误信息
        } finally {
            setLoading(false); // 结束加载
        }
        
    };

    return (
        <div className='container'>
            <div className='login-input'>
                <h2 className='title'>Login</h2>
                <form onSubmit={handleLogin}>
                    <div className='form-group'>
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button className='submit-button' type="submit">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
