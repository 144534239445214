import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { isAuthenticated, getToken } from './Utils';
import './BlogDetail.css'; // 引入 CSS 文件

function BlogDetail() {
  const { shortName } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); // 使用useNavigate跳转页面

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (isAuthenticated()) {
      headers['Authorization'] = 'Bearer ' + getToken();
    }
    fetch(`${apiUrl}/blog/${shortName}`, {
      method: 'GET',
      headers: headers,
    })
      .then(response => response.json())
      .then(data => {
        setBlog(data)
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog:', error)
        setLoading(false);
      });
  }, [shortName, apiUrl, navigate]);

  if (loading) {
    return <div className='loading'>加载中...</div>;
  }

  if (blog.isPrivate && !isAuthenticated()) {
    return <div className='private-blog-reminder'>这是一篇私密博客，请登录后查看。</div>;
  }

  return (
    <div className="blog-detail">
      {blog ? (
        <>
          <div className="header">
            <h2>{blog.blogTitle}</h2>
            {isAuthenticated() && (
              <button className="edit-button" onClick={() => navigate(`/blog/edit/${shortName}`)}>编辑</button>
            )}
          </div>
          <p className='blog-create-date'>{new Date(blog.createTime).toISOString().split('T')[0]}</p>
          <div className='blog-content' dangerouslySetInnerHTML={{ __html: blog.blogContent }} />
        </>
      ) : (
        <p>Loading blog...</p>
      )}
    </div>
  );
}

export default BlogDetail;
