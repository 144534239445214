import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>隐私政策</h1>
      <div className="policy-content">
        <section>
          <h2>1. 信息收集</h2>
          <p>我们可能收集以下类型的信息：</p>
          <ul>
            <li>浏览器类型和设置</li>
            <li>设备信息</li>
            <li>IP地址</li>
            <li>Cookie数据</li>
            <li>您与网站交互的信息</li>
          </ul>
        </section>

        <section>
          <h2>2. 信息使用</h2>
          <p>我们收集的信息用于：</p>
          <ul>
            <li>提供和维护网站服务</li>
            <li>改善用户体验</li>
            <li>分析网站使用情况</li>
            <li>提供个性化的内容和广告</li>
          </ul>
        </section>

        <section>
          <h2>3. Cookie使用</h2>
          <p>我们使用Cookie来：</p>
          <ul>
            <li>记住用户偏好设置</li>
            <li>理解和保存用户偏好</li>
            <li>跟踪网站访问数据</li>
            <li>监控广告效果</li>
          </ul>
        </section>

        <section>
          <h2>4. 第三方服务</h2>
          <p>我们的网站使用以下第三方服务：</p>
          <ul>
            <li>Google Analytics - 用于网站访问分析</li>
            <li>Google AdSense - 用于展示广告</li>
          </ul>
        </section>

        <section>
          <h2>5. 数据安全</h2>
          <p>我们采取适当的数据收集、存储和处理措施，以及安全措施来保护您的个人信息。</p>
        </section>

        <section>
          <h2>6. 隐私政策的变更</h2>
          <p>我们可能会不时更新我们的隐私政策。我们会在本页面上发布任何隐私政策的变更。</p>
        </section>

        <section>
          <h2>7. 联系我们</h2>
          <p>如果您对本隐私政策有任何疑问，请通过以下方式联系我们：</p>
          <p>邮箱：laisinanvictor@gmail.com</p>
        </section>

        <footer>
          <p>最后更新时间：{new Date().toISOString().split('T')[0]}</p>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy; 