import React, { useEffect, useState } from 'react';
import { marked } from "marked";
import './About.css'; // 引入 CSS 文件

const About = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/README.md')
      .then(response => response.text())
      .then(text => {
        // 使用 marked 将 Markdown 转换为 HTML
        const convertedHtml = marked(text);
        setHtmlContent(convertedHtml);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    // 渲染转换后的 HTML
    <div className="about-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default About;
