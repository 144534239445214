import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // 导入 useAuth

const Logout = () => {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth(); // 使用自定义 hook

    useEffect(() => {
        // 从 localStorage 中删除 token
        localStorage.removeItem('token');

        // 更新认证状态
        setIsAuthenticated(false);

        // 跳转到首页
        navigate('/');
    }, [navigate, setIsAuthenticated]);

    return (
        <div>
            <h2>Logging out...</h2>
        </div>
    );
};

export default Logout;
