import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import BlogList from './components/BlogList';
import BlogDetail from './components/BlogDetail';
import BlogEdit from './components/BlogEdit';
import Login from './components/Login';
import Logout from './components/Logout';
import About from './components/About';
import { AuthProvider, useAuth } from './components/AuthContext'; // 导入 AuthProvider 和 useAuth
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Header />
                    <main className="App-main">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/" element={<BlogList />} />
                            <Route path="/blog/:shortName" element={<BlogDetail />} />
                            <Route path="/blog/edit/:shortName" element={<BlogEdit />} />
                            <Route path="/blog/create" element={<BlogEdit />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        </Routes>
                    </main>
                    <footer>
                        <p>© 2024 思南小馆. All rights reserved.</p>
                        <div className="footer-links">
                            <a href="/about" className="about">关于</a>
                            <a href="/privacy-policy" className="privacy">隐私政策</a>
                        </div>
                    </footer>
                </div>
            </Router>
        </AuthProvider>
    );
}

function Header() {
    const { isAuthenticated, setIsAuthenticated } = useAuth(); // 使用自定义 hook

    return (
        <header className="App-header" role='banner'>
            <nav role='navigation'>
                <div className='navigation-bar'>
                    <a className='blog-navigation' href="/">
                        <img src="/logo.webp" alt="Logo" className="header-logo" />
                        <h1>思南小馆</h1>
                    </a>
                    {isAuthenticated && (
                        <Link className='create-blog-link' to="/blog/create">
                            <button className="create-blog-button">
                                创建博客
                            </button>
                        </Link>
                    )}
                </div>
            </nav>
        </header>
    );
}

export default App;
