import React, { useEffect, useState } from 'react';
import './BlogList.css'; // 引入 CSS 文件
import { isAuthenticated, getToken } from './Utils';

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const url = isAuthenticated() ? `${apiUrl}/blogs?includePrivate=true` : `${apiUrl}/blogs`;
    const headers = {
      'Content-Type': 'application/json',
    };
    if (isAuthenticated()) {
      headers['Authorization'] = 'Bearer ' + getToken();
    }
    // 发起API请求，获取博客列表
    fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(response => response.json())
      .then(data => {
        setBlogs(data); // 将数据存入state
        setLoading(false); // 设置加载状态为false
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
        setLoading(false); // 确保错误情况下也不一直显示loading
      });
  }, []);

  const renderBlogType = (type) => {
    switch (type) {
      case 'TECHNIQUE':
        return '技术';
      case 'THINKING':
        return '杂感';
      default:
        return '其他'; // 默认类型
    }
  };

  if (loading) {
    return <div className='loading'>加载中</div>; // 加载时显示
  }

  return (
    <div className="blog-list-container">
      <div className="blog-list"> {/* 为列表容器添加 class */}
        <ul className="blog-items">
          <li className="blog-item">
            <span className="blog-title column-title">标题</span>
            <div className="blog-meta">
              <span className="blog-date">时间</span>
              <span className="blog-type">分类</span>
            </div>
          </li>
          {blogs.map(blog => (
            <li key={blog.blogId} className="blog-item"> {/* 为每个博客项目添加 class */}
              <a href={`/blog/${blog.shortName}`} className="blog-title">{blog.blogTitle}</a>
              <div className="blog-meta">
                <span className="blog-date">{new Date(blog.createTime).toISOString().split('T')[0]}</span>
                <span className="blog-type">{renderBlogType(blog.blogType)}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BlogList;
