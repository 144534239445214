import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import './BlogEdit.css';
import { isAuthenticated, getToken, generateSlug } from './Utils';

function BlogEdit() {
  const { shortName } = useParams();
  const [blog, setBlog] = useState(null); // 初始值为 null
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    if (shortName) {
      const headers = {
        'Content-Type': 'application/json',
      };
      if (isAuthenticated()) {
        headers['Authorization'] = 'Bearer ' + getToken();
      }
      fetch(`${apiUrl}/blog/${shortName}`, {
        method: 'GET',
        headers: headers,
      })
      .then(response => response.json())
      .then(data => {
        setBlog(data);
      })
      .catch(error => console.error('Error fetching blog:', error));
    } else {
      // 如果没有 id，表示是创建模式，初始化 blog 对象为空的博客数据
      setBlog({
        blogTitle: '',
        blogContent: '',
        blogType: '',
      });
    }
  }, [shortName, apiUrl]);

  const handleTitleChange = (e) => {
    setBlog({
      ...blog,
      [e.target.name]: e.target.value,
    });
  };

  const handleTypeChange = (e) => {
    setBlog({
      ...blog,
      [e.target.name]: e.target.value,
    });
  };

  const handlePrivateChange = (e) => {
    setBlog({
      ...blog,
      isPrivate: e.target.checked,
    });
  };

  const handleEditorChange = (content, editor) => {
    setBlog({
      ...blog,
      blogContent: content
    });
  };

  async function handleSave() {
    const url = shortName ? `/${blog.blogId}` : '';
    const content = blog.blogContent.replace(/<p><br><\/p>+/g, '');
    const slug = await generateSlug(blog.blogTitle).then();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (isAuthenticated()) {
      headers['Authorization'] = 'Bearer ' + getToken();
    }
    fetch(`${apiUrl}/blogs${url}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        ...blog,
        blogContent: content,
        shortName: slug,
      }),
    })
    .then(response => response.json())
    .then(data => {
      // 假设服务器返回的数据中包含新创建或更新的博客的 ID
      const shortName = data.shortName;
      navigate(`/blog/${shortName}`); // 保存成功后跳转到详情页面
    })
    .catch(error => console.error('Error saving blog:', error));
  };

  // 添加 null 检查，确保在 blog 数据加载完之前不会访问 blog.blogTitle
  if (!blog) {
    return <div className='loading'>加载中...</div>;
  }

  return (
    <div className="blog-edit">
      <div className='blog-edit-title'>{shortName ? '编辑博客' : '创建博客'}</div>
      <div className="blog-edit-header">
        <input
          type="text"
          name="blogTitle"
          value={blog.blogTitle}
          onChange={handleTitleChange}
          placeholder="博客标题"
          className="blog-title-input"
        />
        <div className="blog-options">
          <div className="blog-type-select">
            <label htmlFor="blogType">博客分类:</label>
            <select id="blogType" name="blogType" value={blog.blogType} onChange={handleTypeChange}>
              <option value="">--选择分类--</option>
              <option value="TECHNIQUE">技术</option>
              <option value="THINKING">杂感</option>
            </select>
          </div>
          <div className="blog-private-checkbox">
            <input
              type="checkbox"
              id="isPrivate"
              name="isPrivate"
              checked={blog.isPrivate}
              onChange={handlePrivateChange}
            />
            <label htmlFor="isPrivate">设为私密博客</label>
          </div>
        </div>
      </div>
      <Editor
        apiKey="3hbki7on1j40gota7bo3bto0hfmlvvw81em26dccrcwrz345"
        value={blog.blogContent}
        init={{
          height: 800,
          menubar: false,
          plugins: [
            'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 
            'wordcount', 'textcolor', 'advlist'
          ],
          toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | ' + 
                   'addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | numlist bullist indent outdent | ' + 
                   'emoticons charmap | removeformat | forecolor backcolor',
          tinycomments_mode: 'embedded',
          tinycomments_author: 'Author name',
          mergetags_list: [
            { value: 'First.Name', title: 'First Name' },
            { value: 'Email', title: 'Email' },
          ],
          ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
          advlist_number_styles: 'default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman',
        }}
        onEditorChange={handleEditorChange}
      />
      <button className='save-button' onClick={handleSave}>{shortName ? '保存' : '创建'}</button>
    </div>
  );
}

export default BlogEdit;
